<template>
  <div class="elv-ledger-chart-or-account-table-container">
    <el-table
      ref="tableListRef"
      v-table-skeleton="{ loading: props.tableLoading }"
      :data="props.tableData"
      border
      height="100%"
      row-key="code"
      :tree-props="{ children: 'list' }"
      default-expand-all
      class="elv-chartOfAccount-table"
      header-cell-class-name="elv-chartOfAccount-table-header__cell"
      header-row-class-name="elv-chartOfAccount-table-header"
      row-class-name="elv-chartOfAccount-table-row"
      cell-class-name="elv-chartOfAccount-table-row__cell"
      @row-click="onCellClicked"
    >
      <el-table-column
        min-width="160"
        class-name="elv-chartOfAccount-table-row__accountId"
        label-class-name="elv-chartOfAccount-table-row__accountId-label"
      >
        <template #header>
          <div />
          <span>{{ t('common.courseAccountId') }}</span>
        </template>
        <template #default="{ row }">
          <span>{{ row.code }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('common.accountName')"
        min-width="220"
        class-name="elv-chartOfAccount-table-row__accountName"
      >
        <template #default="{ row }"> {{ locale === 'en' ? row.name : row?.nameCN || row.name }} </template>
      </el-table-column>
      <el-table-column
        :label="t('title.balanceType')"
        min-width="100"
        class-name="elv-chartOfAccount-table-row__balanceType"
      >
        <template #default="{ row }">{{ row.isLeaf ? `${row?.defaultBalanceType}.` : '' }}</template>
      </el-table-column>
      <el-table-column
        :label="t('report.auxiliaryCode')"
        min-width="140"
        class-name="elv-chartOfAccount-table-row__auxiliaryCode"
      >
        <template #default="{ row }">
          <div
            v-for="(item, index) in row?.auxiliaryTypes"
            :key="item.name"
            class="elv-chartOfAccount-table-row__auxiliaryCode-item"
          >
            <template v-if="index > 0">/</template>
            {{ item?.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="t('report.originalCurrency')" min-width="132">
        <template #default="{ row }">
          <SvgIcon
            v-if="row.isLeaf && ['ASSET', 'LIABILITY'].includes(row.type) && !row.currencyTypes.includes('REPORTING')"
            name="report-checkmark"
            width="14"
            height="14"
            fill="#2F63EB"
          />
        </template>
      </el-table-column>
      <el-table-column :label="t('report.cashAccount')" min-width="132">
        <template #default="{ row }">
          <SvgIcon
            v-if="row.isLeaf && row?.isCashAccount"
            name="report-checkmark"
            width="14"
            height="14"
            fill="#2F63EB"
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="t('report.Description')"
        min-width="160"
        class-name="elv-chartOfAccount-table-row__description"
      >
        <template #default="{ row }">
          {{ row?.description }}
        </template>
      </el-table-column>
      <el-table-column :label="t('common.createdBy')" min-width="120">
        <template #default="{ row }">
          {{ row?.creatorType !== 'USER' ? t('common.system') : row?.user?.name }}
        </template>
      </el-table-column>
      <el-table-column label="" width="80" class-name="elv-chartOfAccount-table-row__operating" align="center">
        <template #default="{ row }">
          <template v-if="row.isLeaf">
            <SvgIcon name="sources-edit" width="18" fill="#838D95" height="18" @click="onEditChartOfAccount(row)" />
            <SvgIcon
              v-if="row?.creatorType !== 'SYSTEM'"
              name="sources-delete"
              width="18"
              height="18"
              fill="#838D95"
              @click="onDeleteChartOfAccount(row)"
            />
          </template>
        </template>
      </el-table-column>
    </el-table>
    <DeleteCOADialog ref="deleteCOADialogRef" :itemData="currentItemData" @onResetList="emit('onResetList')" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import DeleteCOADialog from './DeleteCOADialog.vue'
import { useEntityStore } from '@/stores/modules/entity'

const props = defineProps({
  tableData: {
    type: Array,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  }
})

const { t, locale } = useI18n()

const tableListRef = useTemplateRef('tableListRef')
const deleteCOADialogRef = useTemplateRef('deleteCOADialogRef')
const currentItemData: any = ref({})
const entityStore = useEntityStore()
const overlayDrawerData = reactive({
  currentData: {}
})

const emit = defineEmits(['onEdit', 'onResetList'])

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

// eslint-disable-next-line no-unused-vars
const onCellClicked = async (row: any, column: any, e: any) => {
  try {
    overlayDrawerData.currentData = row
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}

const handleArr = (arr: any, isExpand: boolean) => {
  arr.forEach((i: any) => {
    tableListRef.value?.toggleRowExpansion(i, isExpand)
    if (i.list) {
      handleArr(i.list, isExpand)
    }
  })
}

const onCheckTableExpand = (status: string) => {
  nextTick(() => {
    handleArr(props.tableData, status === 'EXPAND')
  })
}

const onEditChartOfAccount = async (row: any) => {
  try {
    if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    currentItemData.value = row
    emit('onEdit', row)
  } catch (error) {
    console.error(error)
  }
}

const onDeleteChartOfAccount = (row: any) => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentItemData.value = row
  deleteCOADialogRef.value?.onCheckDeleteDialog()
}

defineExpose({ onCheckTableExpand })
</script>

<style lang="scss">
.elv-chartOfAccount-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    // height: 616px;

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .elv-chartOfAccount-table-header {
    background: #eef4fb;

    .elv-chartOfAccount-table-header__cell {
      background: #eef4fb;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &:last-child {
        border-right: 0px;
      }

      &.is-center {
        .cell {
          justify-content: center;
        }
      }

      &.elv-chartOfAccount-table-row__accountId-label {
        .cell {
          padding-left: 0px;
          height: 100%;

          > div {
            width: 40px;
            height: 100%;
            // box-sizing: border-box;
            border-right: 1px solid #e4e7eb;
            margin-right: 10px;
          }
        }
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .el-table__row.elv-chartOfAccount-table-row {
    background-color: #fff;

    &.el-table__row--level-0 .elv-chartOfAccount-table-row__cell,
    .elv-chartOfAccount-table-row__cell {
      background-color: #fff;

      &.elv-chartOfAccount-table-row__accountName {
        .cell {
          font-size: 13px;
          font-weight: 700;
          word-break: break-word;
        }
      }

      span {
        padding-left: 10px;
      }
    }

    &.el-table__row--level-1 .elv-chartOfAccount-table-row__cell {
      background-color: #f9f9f9;

      &.elv-chartOfAccount-table-row__accountName {
        .cell {
          font-weight: 500;
        }
      }

      &.elv-chartOfAccount-table-row__accountId span {
        padding-left: 30px;
      }
    }

    &.el-table__row--level-2 .elv-chartOfAccount-table-row__cell {
      background-color: #f9f9f9;

      &.elv-chartOfAccount-table-row__accountName {
        .cell {
          font-weight: 500;
        }
      }

      &.elv-chartOfAccount-table-row__accountId span {
        padding-left: 50px;
      }
    }

    &.el-table__row--level-3 .elv-chartOfAccount-table-row__cell {
      background-color: #f9f9f9;

      &.elv-chartOfAccount-table-row__accountName {
        .cell {
          font-weight: 500;
        }
      }

      &.elv-chartOfAccount-table-row__accountId span {
        padding-left: 70px;
      }
    }

    &.el-table__row--level-4 .elv-chartOfAccount-table-row__cell {
      background-color: #f9f9f9;

      &.elv-chartOfAccount-table-row__accountName {
        .cell {
          font-weight: 500;
        }
      }

      &.elv-chartOfAccount-table-row__accountId span {
        padding-left: 90px;
      }
    }

    &.el-table__row--level-5 .elv-chartOfAccount-table-row__cell {
      background-color: #f9f9f9;

      &.elv-chartOfAccount-table-row__accountName {
        .cell {
          font-weight: 500;
        }
      }

      &.elv-chartOfAccount-table-row__accountId span {
        padding-left: 110px;
      }
    }
  }

  .elv-chartOfAccount-table-row__cell {
    padding: 0;
    height: 42px;

    &:last-child {
      border-right: 0px;
    }

    &.is-center {
      .cell {
        justify-content: center;
      }
    }

    &.elv-chartOfAccount-table-row__accountId {
      height: 42px;

      .cell {
        display: flex;
        padding-left: 0px;
        box-sizing: border-box;
        align-items: center;
        height: 100%;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        .el-table__indent {
          padding-left: 0px !important;
          border-left: 0px;
        }

        .el-table__placeholder {
          width: 40px;
          box-sizing: border-box;
          height: 100%;
          padding-left: 40px !important;
          border-left: 0px;
        }

        span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid #edf0f3;
          white-space: nowrap;
        }

        .el-table__expand-icon {
          width: 40px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          margin-right: 0px;
        }
      }
    }

    &.elv-chartOfAccount-table-row__accountName {
      .cell {
        font-weight: 500;
      }
    }

    &.elv-chartOfAccount-table-row__description {
      .cell {
        padding: 13px 10px;
      }
    }

    &.elv-chartOfAccount-table-row__operating {
      .cell {
        display: flex;
        gap: 8px;

        svg {
          fill: #838d95;

          &.is-disabled {
            cursor: not-allowed !important;
          }

          &:not(.is-disabled):hover {
            cursor: pointer;
            fill: #1e2024;
          }
        }
      }
    }

    &.elv-chartOfAccount-table-row__auxiliaryCode .cell {
      display: flex;

      .elv-chartOfAccount-table-row__auxiliaryCode-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        margin-right: 4px;
        white-space: nowrap;

        &:last-of-type {
          margin-right: 0px;
        }
      }
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
    }

    .elv-chartOfAccount-table-row__cell-activity {
      font-weight: 500;
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .el-table__footer-wrapper {
    .el-table__footer {
      height: 42px;

      .cell.elv-chartOfAccount-table-row__total {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .cell.elv-chartOfAccount-table-row__amount {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;

        p {
          color: #838d95;
          font-family: 'Barlow';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: none;
        }
      }
    }
  }
}

.elv-chartOfAccount-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}
</style>
<style lang="scss" scoped>
.elv-ledger-chart-or-account-table-container {
  flex: 1;
  min-height: 0;
  position: relative;
}
</style>
